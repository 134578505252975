import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // products: any[] = []; 
  public images = [
    
    { src: "https://reposteria-images.s3.amazonaws.com/58a7c995-7690-4ffe-8dfe-6ae79ba8f928.jpg" },
    { src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-02-46.jpg" },
    { src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-02-52.jpg" },
    { src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-03-11.jpg" },
    { src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-07-52.jpg" },
    { src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-02-50.jpg" },
  ];
  message:string;
  subscription: Subscription;
  constructor(private data: DataService) { }

  ngOnInit() {
    // this.subscription = this.data.sharedParam.subscribe(message => this.message = message);
    console.log(window.paypal);
  }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

  newMessage() {
    this.data.changeParam([])
  }

}
