<section id="main">
        <section class="thumbnails row">
            <div class="col-lg-4 my-4" *ngFor="let img of images">
                <a (click)="viewImage(img)">
                    <img class="square" src="{{img.src}}" alt="" />
                    <h3>{{img.title}}</h3>
                </a>
            </div>
        </section>
</section>
