<div class="container text-center">
    <div class="row">
        <div class="col-12 mt-5">
            <h1 class="text-center">Contacto</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 mt-5">
            <p class="font">Email: <a href="mailto:reposteria.gissella@gmail.com">reposteria.gissella@gmail.com</a></p>
        </div>
    </div>
    <div class="row">
        <!-- <div class="col-12 mt-5">
            <p class="font">Telefono: 532-323-2323</p>
        </div> -->
    </div>
</div>