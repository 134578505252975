import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { CartComponent } from './shopping-cart/cart/cart.component';

const routes: Routes = [
  // {pathMatch:'full', redirectTo: ''},
  {path: 'contacto', component: ContactComponent},
  {path: 'cart', component: CartComponent},

  {path: '', component: HomeComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
