import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { CdkStepperModule} from "@angular/cdk/stepper";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { CdkTableModule } from "@angular/cdk/table";
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';

@NgModule({
  declarations: [],
  imports: [CommonModule,MatDialogModule],
  exports: [
    CommonModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormsModule,
    CdkStepperModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    CdkTableModule,
    MatToolbarModule,
    ScrollingModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatStepperModule  ]

})
export class SharedModule {}
