import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cart-button',
  templateUrl: './cart-button.component.html',
  styleUrls: ['./cart-button.component.scss']
})
export class CartButtonComponent implements OnInit {

  products: any[];
  subscription: Subscription;
  constructor(private loc: Router, private data: DataService) { }

  ngOnInit() {
    this.subscription = this.data.sharedParam.subscribe(p => this.products = p);
  }

  goToCart() {
    this.loc.navigate(['cart']);
  }
}