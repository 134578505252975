<div class="row carousel center">
  <ng-container class="col-12" *ngFor="let slide of slides; let i = index">
    <img *ngIf="i === currentSlide" [src]="slide.src" class="slide" @carouselAnimation />
  </ng-container>
  <button class="control prev col-2" (click)="onPreviousClick()">
    <span class="arrow left"></span>
  </button>
  <button class="control next col-2" (click)="onNextClick()">
    <span class="arrow right"></span>
  </button>
</div>