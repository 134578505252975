
<header class="row" id="header">
    <span class="col-12"><img
            src="https://reposteria-images.s3.amazonaws.com/c62fba85-b1a3-4c73-b8ab-18de4117cdec_200x200.png"
            alt="" /></span>
    <h1 class="col-12">Todo en casa hecho con amor...<br />
        Con los mejores ingredientes de alta calidad.</h1>
    <ul class="icons col-12">
        <li><a href="#" class="icon style2 fa fa-twitter"><span class="label">Twitter</span></a></li>
        <li><a href="#" class="icon style2 fa fa-facebook"><span class="label">Facebook</span></a></li>
        <li><a href="#" class="icon style2 fa fa-instagram"><span class="label">Instagram</span></a></li>
        <li><a href="mailto:someone@yoursite.com" class="icon style2 fa fa-envelope-o"><span
                    class="label">Email</span></a></li>
    </ul>
</header>
