import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { ReposteriaComponent } from './reposteria/reposteria.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ContactComponent } from './contact/contact.component';
import { PaypalComponent } from './paypal/paypal.component';
import { ImageModalComponent } from './reposteria-images/image-modal/image-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { ImagesComponent } from './reposteria-images/images/images.component';
import { CartComponent } from './shopping-cart/cart/cart.component';
import { CartButtonComponent } from './shopping-cart/cart-button/cart-button.component';
import { DataService } from './services/data.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    FooterComponent,
    ReposteriaComponent,
    CarouselComponent,
    ContactComponent,
    PaypalComponent,  
    HeaderComponent,
    ImagesComponent,
    ImageModalComponent,
    CartComponent,
    CartButtonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    SharedModule
  ],
  exports: [
    SharedModule
  ],
  entryComponents: [ImageModalComponent],
  providers: [
    DataService,
    // { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
