<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center">
            <img width="250vw"
                src="https://reposteria-images.s3.amazonaws.com/c62fba85-b1a3-4c73-b8ab-18de4117cdec_200x200.png"
                alt="">
            <h1 class="title">Reposteria Gissella</h1>
        </div>
    </div>
    <div class="container">
        <app-carousel [slides]="slides"></app-carousel>
    </div>
</div> -->

<div id="wrapper">
    <div class="row">
        <div class="col">
            <app-header></app-header>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <app-images></app-images>
        </div>
    </div>
</div>