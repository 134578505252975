import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  img: any;
  products:any[];
  subscription: Subscription;

  constructor(
    private dataSvc: DataService,
    private dialogRef: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.img = data.image;
  }



  addToCart(img) {
    this.dialogRef.close({ msg: 'you confirmed', img: img })
  }

  closeDialog() {
    this.dialogRef.close({ msg: 'canceled'})
  }

  ngOnInit() {
    this.subscription = this.dataSvc.sharedParam.subscribe(p => this.products = p)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
