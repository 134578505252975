<div class="row">
  <div class="col-12 text-center">
    <h3>
      <img class="img-size" src="{{this.img.src}}" alt="" />
    </h3>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <mat-dialog-actions align="center">
      <a class="activity-link clearfix active">
        <button mat-button (click)=addToCart(this.img)>Add To Cart</button>
      </a>
      <button mat-button [mat-dialog-close]="false" (click)=closeDialog() cdkFocusInitial>Cancel</button>
    </mat-dialog-actions>
  </div>
</div>

<div class="row m-3">
  <div class="col-12 text-center">
    <app-paypal></app-paypal>
  </div>
</div>
