import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  private paramSource = new BehaviorSubject([]);
  sharedParam : any = this.paramSource.asObservable();

  constructor() { }

  changeParam(param: any[]) {
    this.paramSource.next(param)
  }

}
