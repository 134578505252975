import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reposteria',
  templateUrl: './reposteria.component.html',
  styleUrls: ['./reposteria.component.scss']
})
export class ReposteriaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
