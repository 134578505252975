import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
declare global {
  interface Window {
      paypal:any;
  }
}let FB = window.paypal; // ok now
@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})

export class PaypalComponent implements OnInit {

  constructor() { }

  @Input() total;
  @ViewChild('paypalRef', { static: true })
  private paypalRef!: ElementRef;
  ngOnInit() {
    console.log(window.paypal)
    window.paypal.Buttons({
      style: {
        layout: 'horizontal',
        color: 'blue',
        shape: 'rect',
        label: 'paypal',
        size: 'small',
        tagline: false
      },
      createOrder: (data, actions) => {
        // This function sets up the details of the transaction, including the amount and line item details.
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: this.total,
              currency_code: 'USD'
            }
          }]
        });
      },
      onAppove: (data, actions) => {
        console.log('data', data);
        console.log('actions', actions);
        return actions.order.capture()
        .then(details => {
          console.log('details', details);
          alert('Transaction Completed')
        });
      },
      onError: error => {
        console.log(error);
      }
    }).render(this.paypalRef.nativeElement)
  }

}
