import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { DataService } from "src/app/services/data.service";
import { ImageModalComponent } from "../image-modal/image-modal.component";

@Component({
  selector: "app-images",
  templateUrl: "./images.component.html",
  styleUrls: ["./images.component.scss"],
})
export class ImagesComponent implements OnInit {
  products: any[] = [];
  img: any;
  message: string;
  subscription: Subscription;
  public images = [
    {
      src: "https://reposteria-images.s3.amazonaws.com/58a7c995-7690-4ffe-8dfe-6ae79ba8f928.jpg",
      title: "Tortas especiales",
      id: 1,
      type: "unit",
      price: 0.01
    },
    {
      src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-02-46.jpg",
      title: "Tortas festivas",
      id: 2,
      type: "unit",
      price: 25.0,
    },
    {
      src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-02-52.jpg",
      title: "Cupcakes",
      id: 3,
      type: "dozen",
      price: 20.0,
    },
    {
      src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-03-11.jpg",
      title: "Diseños de cumpleaños",
      id: 4,
      type: "unit",
      price: 25.0,
    },
    {
      src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-07-52.jpg",
      title: "Tortas con diseños",
      id: 5,
      type: "unit",
      price: 25.0,
    },
    {
      src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-02-50.jpg",
      title: "Diseños de cumpleaños",
      id: 6,
      type: "unit",
      price: 25.0,
    },
    {
      src: "https://reposteria-images.s3.amazonaws.com/PHOTO-2021-02-05-10-07-41.jpg",
      title: "Tortas de cumpleaños",
      id: 7,
      type: "unit",
      price: 15.0,
    },
  ];

  constructor(private dialog: MatDialog, private dataSvc: DataService) {}

  ngOnInit() {
    console.log(
      (this.subscription = this.dataSvc.sharedParam.subscribe(
        (p) => (this.products = p)
      ))
    );
  }

  viewImage(image: any) {
    const img = image;
    const dialogRef = this.dialog.open(ImageModalComponent, {
      width: "40vw",
      height: "auto",
      panelClass: "dialog-style",
      data: {
        image: img,
      },
      disableClose: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.msg === "you confirmed") {
        this.products.push(img);
        this.dataSvc.changeParam(this.products);
        console.log(result.img);
      } else {
        console.log("canceled");
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
