import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {

  products: any[] = []
  subscription: Subscription;
  total = 0.0;

  constructor(private data: DataService, ) { }

  ngOnInit() {
    this.subscription = this.data.sharedParam.subscribe(p => this.products = p)
    console.log(this.products)
    this.countTotalPrice();
  }

  countTotalPrice() {
    this.products.forEach(p => {
      this.total += p.price;
    });
  }

  goBack() {
    window.history.back()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
