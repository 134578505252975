<div class="container-fluid">
  <div class="row">
    <div class="navbar navbar-expand-lg navbar-light bg-light col-1">
      <a routerLink="/" class="navbar-brand" href="#"> <img width="50vw"
        src="https://reposteria-images.s3.amazonaws.com/c62fba85-b1a3-4c73-b8ab-18de4117cdec_200x200.png"
        alt=""></a>
    </div>
    <div class="navbar navbar-expand-lg navbar-light bg-light col-8">
  </div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light col-3">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" routerLink="/">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/contacto">Contacto</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Ordernar</a>
            </li> -->
            <li class="nav-item">
              <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Recetas</a>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Videos</a>
            </li>
            <li>
              <app-cart-button></app-cart-button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
</div>


